export const ja = {
    to: "に",
    field: "",
    no: "いいえ",
    sns: "SNS",
    yes: "はい",
    age: "年齢",
    next: "次へ",
    back: "戻る",
    date: "日程",
    hour: "時間",
    minute: "分",
    name: "名前",
    from: "から",
    end: "終わり",
    finish: "終了",
    close: "閉じる",
    start: "始める",
    begin: "始める",
    success: "成功",
    email: "Eメール",
    phone: "電話番号",
    confirm: "予約する",
    cancel: "キャンセル",
    status: "スターテス",
    datetime: "予約内容",
    reserved: "予約済み",
    experience: "柔術歴",
    confirmation: "確認",
    shopname: 'ショップ名',
    deleted: "削除しました",
    cancelled: "キャンセル",
    homepage: "ホームページ",
    reservation_number: "予約番号",
    reservation_details: "予約内容",
    passing_by_the_shop: "通りがかり",
    schedule_confirmation: "日程確認",
    customer_information: "お客様情報",
    other_specify: "その他（自由記述）",
    schedule_your_appointment: "予約の日程を設定",
    from_a_friend_specify: "知人の紹介（自由記述）",
    please_enter_your_name: "名前を入力してください",
    please_specify_a_friend: "ご紹介者様をご入力ください",
    please_specify_other_things: "ご記入ください",
    reconfirm_reservation_information: "予約情報の再確認",
    this_event_does_not_exist: "このイベントが存在していません",
    please_enter_your_phone_number: "電話番号を入力してください",
    how_did_you_learn_about_us: "カルペディエム町田を知ったきっかけ",
    thank_you_for_your_reservation: "ご予約ありがとうございました。",
    please_enter_your_email_address: "メールアドレスを入力してください",
    are_you_sure_you_want_to_cancel: "キャンセルしてもよろしいですか？",
    duplicate_reservation_message: "すでにこのレッスンイベントを予約しています。",
    appointment_time_you_must_select_a_time: "予約時間（時間を選択する必要があります）",
    i_have_sent_your_reservation_details_to_your_email: "メールに予約内容を送信しました。",
    use_your_mouse_to_select_a_calendar_date: "マウスを使用してカレンダーの日付を選択します",
    your_reservation_has_been_canceled_and_sent_to_your_email_address: "キャンセルが完了されました。メールにキャンセル内容を送信しました。",
    you_can_check_the_details_of_your_reservation_and_cancel_it_from_the_sent_email: "予約の内容確認とキャンセルは送信したメールからできます。",
}