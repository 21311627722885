import Vue from "vue";
import Vuex from "vuex";

import email from "./modules/email.module";
import event from "./modules/event.module"
import survey from "./modules/survey.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    email,
    event,
    survey,
  }
});
