import Vue from 'vue'
import VueRouter from 'vue-router'
import { PreReservationRememberMiddleware } from '@/middlewares'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'the-calendar-page',
      component: () => import('@/pages/TheCalendarPage.vue'),
      meta: {
        auth: false,
      }
    },
    {
      path: '/user-form',
      name: 'the-user-form-page',
      component: () => import('@/pages/TheUserFormPage.vue'),
      props: (route) => ({
        ...route.params
      }),
      meta: {
        auth: false,
      }
    },
    {
      path: '/confirmation',
      name: 'the-confirmation-page',
      component: () => import('@/pages/TheConfirmationPage.vue'),
      props: (route) => ({
        ...route.params
      }),
      meta: {
        auth: false,
      }
    },
    {
      path: '/summarize/:userId/:reservationNum',
      name: 'the-summarize-page',
      component: () => import('@/pages/TheSummaryPage.vue'),
      meta: {
        auth: false,
      }
    },
  ],
})

router.beforeEach(PreReservationRememberMiddleware)

export default router