import { EventService } from "@/common/api.service";

import {
  FETCH_EVENT,
  FETCH_SUMMARIZE_EVENT,
  SET_RESERVER
} from "../mutations.type"
import {
  LIST_EVENT,
  SUMMARIZE_EVENT,
  CANCEL_EVENT,
  ADD_RESERVATION,
  SET_RESERVER_INFO
}
  from "../actions.type"
import { methods } from "@/plugin/mixin/methods";

const state = {
  event: [],
  reserveration: undefined,
  summarize: {},
  payload: {}
};

const getters = {
  getEvent(state) {
    return state.event;
  },
  getSummarize(state) {
    return state.summarize
  },
  getReservation(state) {
    return state.reserveration;
  }
};

const actions = {
  async [LIST_EVENT]({ commit }, payload) {
    return EventService.listEvent(payload)
      .then(({ data }) => {
        commit(FETCH_EVENT, data.data);
        commit(SET_RESERVER, undefined);
      })
      .catch(error => {
        // console.log(error)
        throw new Error(error);
      });
  },
  async [SUMMARIZE_EVENT]({ commit }, payload) {
    const params = payload.user_id + '/' + payload.reservation_num
    return EventService.summaryEvent(params)
      .then(({ data }) => {
        // console.log(data)
        commit(FETCH_SUMMARIZE_EVENT, data.data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async [CANCEL_EVENT](context, payload) {
    const params = payload?.reservation_num
    return EventService.cancelEvent(params, payload)
      .then(() => {
        // console.log(data)
      })
      .catch(error => {
        // console.log(error)
        throw new Error(error);
      });
  },
  async [ADD_RESERVATION](context, payload) {
    return EventService.createEvent(payload)
      .then((res) => {
        return res;
      })
      .catch(error => {
        throw error;
      });
  },
  [SET_RESERVER_INFO]({ commit }, reserver) {
    commit(SET_RESERVER, reserver);
    if (reserver) {
      const encryptedReserverInfo = methods.encrypt(reserver, process.env.VUE_APP_CRYPTO_SECRECT)
      sessionStorage.setItem('__re_in', encryptedReserverInfo);
    }
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_EVENT](state, event) {
    state.event = event;
  },
  [FETCH_SUMMARIZE_EVENT](state, summarize) {
    state.summarize = summarize;
  },
  [SET_RESERVER](state, reserveration) {
    state.reserveration = reserveration;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
