export const en = {
    no: "No",
    to: "To",
    field: "",
    age: "Age",
    end: "End",
    yes: "Yes",
    sns: "SNS",
    name: "Name",
    back: "Back",
    date: "Date",
    hour: "Hour",
    from: "From",
    next: "Next",
    start: "Start",
    close: "Close",
    email: "Email",
    phone: "Phone",
    begin: "Begin",
    finish: "Finish",
    cancel: "Cancel",
    minute: "Minute",
    status: "Status",
    confirm: "Confirm",
    success: "Success",
    deleted: "Deleted",
    reserved: "Reserved",
    datetime: "Datetime",
    homepage: "homepage",
    shopname: 'Shop Name',
    cancelled: "Cancelled",
    experience: "Experience",
    confirmation: "Confirmation",
    other_specify: "Other(specify)",
    reservation_number: "Reservation Number",
    passing_by_the_shop: "Passing by the shop",
    customer_information: "Customer Information",
    schedule_confirmation: "Schedule Confirmation",
    from_a_friend_specify: "From a friend(specify)",
    please_enter_your_name: "Please enter your name",
    reservation_details: "Summary Reservation Detail",
    please_specify_a_friend: "Please specify a friend",
    schedule_your_appointment: "Schedule Your Appointment",
    this_event_does_not_exist: "This event does not exist",
    how_did_you_learn_about_us: "How did you learn about us?",
    please_enter_your_email_address: "Please enter your email",
    please_specify_other_things: "Please specify other things",
    please_enter_your_phone_number: "Please enter your phone number",
    thank_you_for_your_reservation: "Thank you for your reservation.",
    are_you_sure_you_want_to_cancel: "Are you sure you want to cancel?",
    reconfirm_reservation_information: "Reconfirm Reservation Information",
    duplicate_reservation_message: "You already reserved this lesson event.",
    appointment_time_you_must_select_a_time: "Appointment time(you must select a time)",
    use_your_mouse_to_select_a_calendar_date: "Use your mouse to select a calendar date",
    i_have_sent_your_reservation_details_to_your_email: "I have sent your reservation details to your email.",
    your_reservation_has_been_canceled_and_sent_to_your_email_address: "Your reservation was cancelled and send to your email.",
    you_can_check_the_details_of_your_reservation_and_cancel_it_from_the_sent_email: "You can check the details of your reservation and cancel it from the sent email.",
}