import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { CustomValidator } from '@/plugin/vee-validation/custom'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import "@/assets/style/styles.css"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import i18n from '@/i18n'
import store from '@/store'
import App from '@/App.vue'
import router from '@/router'
import { Mixin } from '@/plugin/mixin'
import ApiService from "@/common/api.service";
import { dictionary } from '@/plugin/vee-validation'

ApiService.init();

Vue.mixin(Mixin);

Vue.use(IconsPlugin)
Vue.use(BootstrapVue)

Vue.use(VeeValidate, dictionary);
CustomValidator.init('ja');

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
