import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/common/config";

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = `${process.env.VUE_APP_CLIENT_URL}`
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const EmailService = {
  confirm(params) {
    return ApiService.post("email/confirm", params);
  },
  cancel(params) {
    return ApiService.post("email/cancel", params);
  }
};

export const EventService = {
  listEvent(payload) {
    //const timezone = payload.timezone.replace("/", " ");
    return ApiService.post("/event/list", payload);
  },
  createEvent(payload) {
    return ApiService.post("reservation/store", payload)
  },
  cancelEvent(params, body) {
    return ApiService.put("reservation/cancel", params, body)
  },
  summaryEvent(params) {
    return ApiService.get("reservation/summary", params)
  }
}

export const SurveyService = {
  listSurveyQuestion(surveyId) {
    return ApiService.get("survey", `${surveyId}`);
  }
}
