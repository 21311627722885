import { EmailService } from "@/common/api.service";

import {
  FETCH_EMAIL
} from "../mutations.type"
import {
  CONFIRM_EMAIL,
  CANCEL_EMAIL,
} from "../actions.type"

const state = {
  email: {}
};

const getters = {
  getEmail(state) {
    return state.email;
  }
};

const actions = {
  async [CONFIRM_EMAIL]({ commit }, payload) {
    return EmailService.confirm(payload)
      .then(({ data }) => {
        commit(FETCH_EMAIL, data);
      })
      .catch(error => {
        // console.log(error)
        throw new Error(error);
      });
  },
  async [CANCEL_EMAIL]({ commit }, payload) {
    return EmailService.cancel(payload)
      .then(({ data }) => {
        commit(FETCH_EMAIL, data);
      })
      .catch(error => {
        // console.log(error)
        throw new Error(error);
      });
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_EMAIL](state) {
    state.email = true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
