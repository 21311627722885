import Vue from 'vue';
import VueI18n from 'vue-i18n'
import { en, ja } from './lang'

const messages = { en, ja }

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'ja', // default
    messages,
});

export default i18n;