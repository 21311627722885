import { Validator } from 'vee-validate'
import { phoneRule, ageBetweenRule, positiveRule } from '@/plugin/vee-validation/custom/rules';

const CustomValidator = {
    init: (language) => {
        Validator.extend('phone', phoneRule);
        Validator.extend('positive', positiveRule);
        Validator.extend('ageBetween', ageBetweenRule, { paramNames: ['min', 'max'] });
        Validator.localize(language);
    }
}

export { CustomValidator }