export const phoneRule = {
    validate(value) {
        const phoneNumberPattern = /^\d{2}(?:-\d{4}-\d{4}|\d{8}|\d-\d{3,4}-\d{4})$/;
        return phoneNumberPattern.test(value);
    }
};

export const ageBetweenRule = {
    validate(value, { min, max } = {}) {
        return Number(min) <= value && Number(max) >= value;
    }
}

export const positiveRule = {
    validate(value) {
        const numericPattern = /\+?(\d+(\.(\d+)?)?|\.\d+)/;
        return value > 1 && numericPattern.test(value);
    }
}