import store from '@/store'
import { methods } from "@/plugin/mixin/methods";
import { SET_RESERVER_INFO } from '@/store/actions.type'

const preReservationRemember = (to, from, next) => {
    if (to.name === `the-calendar-page`) {
        sessionStorage.removeItem('__re_in');
        store.dispatch(SET_RESERVER_INFO, undefined);
        next()
    } else {
        const __re_in = sessionStorage.getItem('__re_in');
        if (__re_in) {
            const subscriber = methods.decrypt(__re_in, process.env.VUE_APP_CRYPTO_SECRECT);
            store.dispatch(SET_RESERVER_INFO, subscriber);
            next()
        }
        else next()
    }
}

export default preReservationRemember