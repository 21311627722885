export const customDictionary = {
    ja: {
        messages: {
            // custom message
            positive: function (n) {
                return "１以上の数字を利用できます"
            },
            ageBetween: function (n, e) {
                return `年齢は${e[0]}〜${e[1]}でなければなりません`
            },
            phone: function (n) {
                return `のフォーマットに従う必要があります`;
            },
            // override message
            _default: function (n) {
                return "の値が不正です"
            },
            after: function (n, e) {
                return e[0] + (e[1] ? "以降" : "の後") + "でなければなりません"
            },
            alpha: function (n) {
                return "はアルファベットのみ使用できます"
            },
            alpha_dash: function (n) {
                return "は英数字とハイフン、アンダースコアのみ使用できます"
            },
            alpha_num: function (n) {
                return "は英数字のみ使用できます"
            },
            alpha_spaces: function (n) {
                return "はアルファベットと空白のみ使用できます"
            },
            before: function (n, e) {
                return e[0] + (e[1] ? "以前" : "よりも前") + "でなければなりません"
            },
            between: function (n, e) {
                return e[0] + "から" + e[1] + "の間でなければなりません"
            },
            confirmed: function (n) {
                return "が一致しません"
            },
            credit_card: function (n) {
                return "が正しくありません"
            },
            date_between: function (n, e) {
                return e[0] + "から" + e[1] + "の間でなければなりません"
            },
            date_format: function (n, e) {
                return e[0] + "形式でなければなりません"
            },
            decimal: function (n, e) {
                void 0 === e && (e = []);
                var t = e[0];
                return void 0 === t && (t = "*"), "は整数及び小数点以下" + ("*" === t ? "" : t) + "桁までの数字にしてください"
            },
            digits: function (n, e) {
                return e[0] + "桁の数字でなければなりません"
            },
            dimensions: function (n, e) {
                return e[0] + "px、高さ" + e[1] + "px以内でなければなりません"
            },
            email: function (n) {
                return "有効なメールアドレスではありません"
            },
            excluded: function (n) {
                return "は不正な値です"
            },
            ext: function (n) {
                return "は有効なファイル形式ではありません"
            },
            image: function (n) {
                return "は有効な画像形式ではありません"
            },
            included: function (n) {
                return "は有効な値ではありません"
            },
            ip: function (n) {
                return "は有効なIPアドレスではありません"
            },
            is: function (n) {
                return "が一致しません"
            },
            is_not: function (n) {
                return "が一致しています"
            },
            length: function (n, e) {
                var t = e[0],
                    r = e[1];
                return r ? t + "文字以上" + r + "文字以下でなければなりません" : t + "文字でなければなりません"
            },
            max: function (n, e) {
                return e[0] + "文字以内にしてください"
            },
            max_value: function (n, e) {
                return e[0] + "以下でなければなりません"
            },
            mimes: function (n) {
                return "は有効なファイル形式ではありません"
            },
            min: function (n, e) {
                return e[0] + "文字以上でなければなりません"
            },
            min_value: function (n, e) {
                return e[0] + "以上でなければなりません"
            },
            numeric: function (n) {
                return "は数字のみ使用できます"
            },
            regex: function (n) {
                return "のフォーマットが正しくありません"
            },
            required: (n) => {
                return "必須項目です"
            },
            size: function (n, e) {
                return function (n) {
                    var e = 1024,
                        t = 0 === (n = Number(n) * e) ? 0 : Math.floor(Math.log(n) / Math.log(e));
                    return 1 * (n / Math.pow(e, t)).toFixed(2) + " " + ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][t]
                }(e[0]) + "以内でなければなりません"
            },
            url: function (n) {
                return "は有効なURLではありません"
            }
        },
    }
}