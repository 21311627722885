import { SurveyService } from '@/common/api.service'
import { FETCH_SURVEY_QUESTION } from '@/store/mutations.type'
import { LIST_SURVEY_QUESTION } from '../actions.type'

const state = {
    survey: {}
}
const getters = {
    getSurvey(state) {
        return state.survey;
    }
}
const actions = {
    async [LIST_SURVEY_QUESTION]({ commit }, survey_id) {
        return SurveyService.listSurveyQuestion(survey_id).then((res) => {
            commit(FETCH_SURVEY_QUESTION, res.data?.data);
        }).catch((err) => {
            // console.log(err)
            alert("Opp! Something went wrong")
        })
    }
}
const mutations = {
    [FETCH_SURVEY_QUESTION](state, surveyQuestions) {
        state.survey = surveyQuestions;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};