import CryptoJS from "crypto-js";

const Encrypt = (content, secret) => {
    try {
        return CryptoJS.AES.encrypt(content, secret).toString();
    } catch (err) {
        return CryptoJS.AES.encrypt(JSON.stringify(content), secret).toString();
    }
}

const Decrypt = (encryptedContent, secret) => {
    const bytes = CryptoJS.AES.decrypt(encryptedContent, secret);

    try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}

export const methods = {
    encrypt: Encrypt,
    decrypt: Decrypt
}